<template>
  <div class="w-full inline-flex items-center">
    <div class="h-px w-full my-7.5 bg-sable"></div>
    <div class="flex ml-2.5">
      <a
        :href="`https://www.facebook.com/sharer/sharer.php?u=${windowUrl}`"
        target="_blank"
        class="inline-flex justify-center w-8 h-8 items-center bg-sable rounded hover:bg-blue transition duration-300"
      >
        <icon-facebook></icon-facebook>
      </a>
      <a
        :href="`https://twitter.com/intent/tweet?url=${windowUrl}`"
        target="_blank"
        class="inline-flex justify-center w-8 h-8 items-center bg-sable rounded mx-2.5 hover:bg-blue transition duration-300"
      >
        <icon-twitter></icon-twitter>
      </a>
      <a
        :href="`https://www.linkedin.com/shareArticle?mini=true&url=${windowUrl}&title=&summary=&source=`"
        target="_blank"
        class="inline-flex justify-center w-8 h-8 items-center bg-sable rounded hover:bg-blue transition duration-300"
      >
        <icon-linkedin></icon-linkedin>
      </a>
    </div>
  </div>
</template>

<script>
import IconFacebook from "~/assets/svg/fb3.svg";
import IconTwitter from "~/assets/svg/twitter.svg";
import IconLinkedin from "~/assets/svg/linkedin.svg";

export default {
  name: "SectionContainer",
  setup() {
    const route = useRoute();
    const url = useRequestURL();

    return { windowUrl: url.href, windowTitle: route.title };
  },
  components: {
    IconFacebook,
    IconTwitter,
    IconLinkedin,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>

<i18n lang="json">
{
  "fr": {
    "see-all": "Voir tout"
  },
  "en": {
    "see-all": "See all"
  }
}
</i18n>
